<template>
  <div style="height: 100%">
    <div>
     <el-form class="selfForm" ref="form" size="small"	 :inline="true" label-width="50px">
      <el-form-item size="small" label="姓名">
       <el-input
        v-model="textNameConfig.text"
        size="small"
       ></el-input>
      </el-form-item>
      <el-form-item label="性别" size="small" style="margin-left: 24px">
       <el-select size="small" v-model="textAceConfig.text" placeholder="请选择" class="custom-select">
        <el-option
         v-for="option in aceActions"
         :key="option.value"
         :label="option.label"
         :value="option.value"
        ></el-option>
       </el-select>
      </el-form-item>
      <el-form-item label="眼别" size="small">
       <el-select size="small" v-model="textEysConfig.text" placeholder="请选择" class="custom-select">
        <el-option
         v-for="option in eysActions"
         :key="option.value"
         :label="option.label"
         :value="option.value"
        ></el-option>
       </el-select>
      </el-form-item>
      <el-form-item label="序号" style="margin-left: 24px" size="small">
       <el-input
        v-model="textSerialConfig.text"
        max="180"
        min="0"
        size="small"
       ></el-input>
      </el-form-item>
     </el-form>
      <v-stage ref="stage" :config="stageConfig">
        <v-layer ref="layer">
          <v-image ref="image" :config="imageConfig" />
          <v-ring :config="outerRingConfig" />
          <v-circle :config="innerCircleConfig" />
          <v-group v-for="(tick, index) in ticks" :key="'ticks_' + index">
            <v-line :key="'tick-' + index" :config="tick.line" />
            <v-text :key="'label-' + index" :config="tick.label" />
          </v-group>
          <v-line
            :config="item"
            v-for="(item, index) in tickLine"
            :key="index"
          />
         <v-rect  :config="configRect"></v-rect>
         <v-text  ref="textName" :config="textNameConfig"></v-text>
         <v-text  ref="textAce" :config="textAceConfig"></v-text>
         <v-text  ref="textSerial" :config="textSerialConfig"></v-text>
         <v-text  ref="textEys" :config="textEysConfig"></v-text>
        </v-layer>
      </v-stage>
      <div
        style="
          position: fixed;
          bottom: 70px;
          left: 0px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          box-sizing: border-box;
        "
      >
        <div>
          <div style="display: flex; align-items: center">
            <el-button
              type="primary"
              @mousedown.native.prevent="mousedownFn('left')"
              @mouseup.native.prevent="mouseupFn('left')"
              @touchstart.native.prevent="mousedownFn('left')"
              @touchend.native.prevent="mouseupFn('left')"
              style="margin-right: 10px"
              :disabled="isLock"
              size="small"
              >左转</el-button
            >
            <el-button
              type="primary"
              @mousedown.native.prevent="mousedownFn('right')"
              @mouseup.native.prevent="mouseupFn('right')"
              @touchstart.native.prevent="mousedownFn('right')"
              @touchend.native.prevent="mouseupFn('right')"
              style="margin-left: 9px"
              :disabled="isLock"
              size="small"
              >右转</el-button
            >
          </div>
          <el-button
            :type="isLock ? 'danger' : 'primary'"
            style="margin-top: 10px; width: 130.8px"
            @click="lockFn"
            size="small"
          >
            {{ isLock ? "解锁转盘" : "锁定转盘" }}</el-button
          >
        </div>
        <div style="display: flex; flex-flow: column; align-items: center">
          <div style="display: flex; align-items: center">
            <span style="font-size: 12px; margin-right: 10px"
              >散光轴位(红)</span
            >
            <el-input
              v-model="tickLine1"
              @input="drawTickLine"
              type="number"
              :disabled="!isLock"
              max="180"
              min="0"
              style="width: 60px"
              size="small"
            ></el-input>
            <span style="margin-left: 10px; font-size: 12px">度</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <span style="font-size: 12px; margin-right: 10px"
              >切口位置(蓝)</span
            >
            <el-input
              v-model="tickLine2"
              type="number"
              @input="drawTickLine"
              :disabled="!isLock"
              max="180"
              min="0"
              style="width: 60px"
              size="small"
            ></el-input>
            <span style="margin-left: 10px; font-size: 12px">度</span>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          position: fixed;
          bottom: 20px;
          width: 100%;
          left: 0;
          justify-content: space-evenly;
        "
      >
        <el-upload action="#" accept="image/*" :before-upload="beforeUpload">
          <i
            class="icon el-icon-picture-outline"
            style="font-size: 24px"
            slot="trigger"
          />
        </el-upload>
        <i
          class="icon el-icon-refresh"
          @click="rotationImage()"
          style="font-size: 24px"
        />
        <i
          class="icon el-icon-zoom-out"
          @click="zoomOut()"
          style="font-size: 24px"
        />
        <i
          class="icon el-icon-zoom-in"
          @click="zoomIn()"
          style="font-size: 24px"
        />
        <i
          class="icon el-icon-refresh-right"
          @click="refresh"
          style="font-size: 24px"
        />
        <i
          class="icon el-icon-download"
          @click="exportImage"
          style="font-size: 24px"
        />
      </div>
    </div>

    <el-dialog
      title="长按图片保存到本地"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <img :src="dataURL" style="width: 100%" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const width = window.innerWidth;
const height = window.innerHeight;
export default {
  data() {
    return {
     showAct:false,
     aceActions : [
      { value: '女', label: '女' },
      { value: '男', label: '男' },
     ],
     eysActions : [
      { value: 'OD', label: 'OD' },
      { value: 'OS', label: 'OS' },
     ],
     textNameConfig: {
      x: width / 1.75,
      y: height/1.16,
      width:120,
      align:'left',
      visible: false,
      text: '',
      fontSize: 14,
      fill: '#333',
     },
     textAceConfig:{
      x: width / 1.75,
      y: height/1.115,
      width:120,
      align:'left',
      visible: false,
      text: '',
      fontSize: 14,
      fill: '#333',
     },
     textSerialConfig:{
      x: width / 1.75,
      y: height/1.03,
      width:160,
      align:'left',
      visible: false,
      text: '',
      fontSize: 14,
      fill: '#333',
     },
     textEysConfig:{
      x: width / 1.75,
      y: height/1.07,
      width:120,
      align:'left',
      visible: false,
      text: '',
      fontSize: 14,
      fill: '#333',
     },
     configRect: {
      x:  width/1.8,
      y: height/1.19,
      width: width/1.6,  // 根据实际文本内容和大小调整
      height: 400,  // 根据实际文本内容和大小调整
      visible: false,
      fill: 'rgba(255,255,255,0.6)'
      // fill: 'red'
     },
      dataURL: "",
      dialogVisible: false,
      rotationImageVal: 0,
      isLock: false,
      rotationVal: "",
      tickLine1: "",
      tickLine2: "",
      tickLine: [],
      stageConfig: {
        width: width,
        height: height,
      },
      outerRingConfig: {
        x: width / 2,
        y: height / 2,
        innerRadius: 150,
        outerRadius: 190,
        fill: "rgba(255, 255, 255, 0.3)",
        stroke: "transparent",
        strokeWidth: 1,
      },
      innerCircleConfig: {
        x: width / 2,
        y: height / 2,
        radius: 150,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 1,
      },
      imageConfig: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        image: null,
        draggable: true,
      },
      imageWidth: 0,
      imageHeight: 0,
      ticks: [],
      ticksData: [
        0, 179, 178, 177, 176, 175, 174, 173, 172, 171, 170, 169, 168, 167, 166,
        165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 155, 154, 153, 152,
        151, 150, 149, 148, 147, 146, 145, 144, 143, 142, 141, 140, 139, 138,
        137, 136, 135, 134, 133, 132, 131, 130, 129, 128, 127, 126, 125, 124,
        123, 122, 121, 120, 119, 118, 117, 116, 115, 114, 113, 112, 111, 110,
        109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97, 96, 95,
        94, 93, 92, 91, 270, 89, 88, 87, 86, 85, 84, 83, 82, 81, 80, 79, 78, 77,
        76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61, 60, 59,
        58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41,
        40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23,
        22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3,
        2, 1, 180, 179, 178, 177, 176, 175, 174, 173, 172, 171, 170, 169, 168,
        167, 166, 165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 155, 154,
        153, 152, 151, 150, 149, 148, 147, 146, 145, 144, 143, 142, 141, 140,
        139, 138, 137, 136, 135, 134, 133, 132, 131, 130, 129, 128, 127, 126,
        125, 124, 123, 122, 121, 120, 119, 118, 117, 116, 115, 114, 113, 112,
        111, 110, 109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97,
        96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83, 82, 81, 80, 79,
        78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61,
        60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43,
        42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25,
        24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6,
        5, 4, 3, 2, 1,
      ],
      timer: null,
      localIds: "",
    };
  },
  mounted() {
   this.createTicks();
   this.tickLineInit();
  },
  methods: {
    mousedownFn(val) {
      if (this.isLock) return;
      this.timer = setInterval(() => {
        this.rotationAuto(val);
      }, 0);
      return false;
    },
    mouseupFn() {
      if (this.isLock) return;
      clearInterval(this.timer);
    },
    rotationAuto(val) {
      if (val === "left") {
        const data = this.ticksData.slice(0, Number(this.rotationVal || 1));
        this.ticksData.splice(0, Number(this.rotationVal || 1));
        this.ticksData.push(...data);
      } else {
        const data = this.ticksData.slice(
          this.ticksData.length - Number(this.rotationVal || 1)
        );
        this.ticksData.splice(
          this.ticksData.length - Number(this.rotationVal || 1)
        );
        this.ticksData.unshift(...data);
      }
      this.createTicks();
      this.tickLineInit();
    },
    exportImage: function () {
     this.textNameConfig.visible = this.textSerialConfig.visible = this.configRect.visible = this.textAceConfig.visible =this.textEysConfig.visible=true;
     this.textNameConfig.text = '姓名：'+ this.textNameConfig.text
     this.textAceConfig.text = '姓别：'+ this.textAceConfig.text
     this.textEysConfig.text = '眼别：'+ this.textEysConfig.text
     this.textSerialConfig.text = '序号：'+ this.textSerialConfig.text
     setTimeout(()=>{
       const stage = this.$refs.stage.getStage();
       this.dataURL = stage.toDataURL({
        mimeType: "image/jpeg",
        quality: 1,
        pixelRatio: 4,
       });
      this.dialogVisible = true;
      this.textNameConfig.text = this.textAceConfig.text = this.textSerialConfig.text =this.textEysConfig.text = ''
      this.textNameConfig.visible = this.textSerialConfig.visible = this.textAceConfig.visible=this.configRect.visible=false;
     },200)
     // console.log(this.dataURL);
    },
    refresh() {
      const image = this.$refs.image.getStage();
      this.rotationVal = "";

      this.isLock = false;
      image.scale({
        x: 1,
        y: 1,
      });
      image.rotation(0);

      const aspectRatio = this.imageWidth / this.imageHeight;
      const maxWidth = this.stageConfig.width;
      const maxHeight = this.stageConfig.height;
      if (maxWidth / aspectRatio <= maxHeight) {
        this.imageConfig.width = maxWidth;
        this.imageConfig.height = maxWidth / aspectRatio;
      } else {
        this.imageConfig.width = maxHeight * aspectRatio;
        this.imageConfig.height = maxHeight;
      }
      this.imageConfig.x = 0;
      this.imageConfig.y =
        (this.stageConfig.height - this.imageConfig.height) / 2;
      image.x(0);
      image.y((this.stageConfig.height - this.imageConfig.height) / 2);
      this.tickLine1 = "";
      this.tickLine2 = "";
      this.tickLine = [this.tickLine[0]];
      this.ticksData = [
        0, 179, 178, 177, 176, 175, 174, 173, 172, 171, 170, 169, 168, 167, 166,
        165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 155, 154, 153, 152,
        151, 150, 149, 148, 147, 146, 145, 144, 143, 142, 141, 140, 139, 138,
        137, 136, 135, 134, 133, 132, 131, 130, 129, 128, 127, 126, 125, 124,
        123, 122, 121, 120, 119, 118, 117, 116, 115, 114, 113, 112, 111, 110,
        109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97, 96, 95,
        94, 93, 92, 91, 270, 89, 88, 87, 86, 85, 84, 83, 82, 81, 80, 79, 78, 77,
        76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61, 60, 59,
        58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41,
        40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23,
        22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3,
        2, 1, 180, 179, 178, 177, 176, 175, 174, 173, 172, 171, 170, 169, 168,
        167, 166, 165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 155, 154,
        153, 152, 151, 150, 149, 148, 147, 146, 145, 144, 143, 142, 141, 140,
        139, 138, 137, 136, 135, 134, 133, 132, 131, 130, 129, 128, 127, 126,
        125, 124, 123, 122, 121, 120, 119, 118, 117, 116, 115, 114, 113, 112,
        111, 110, 109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97,
        96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83, 82, 81, 80, 79,
        78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61,
        60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43,
        42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25,
        24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6,
        5, 4, 3, 2, 1,
      ];

      this.createTicks();
      this.tickLineInit();
    },
    lockFn() {
      this.isLock = !this.isLock;
      if (!this.isLock) {
        this.tickLine1 = "";
        this.tickLine2 = "";
      }
      this.tickLine = [this.tickLine[0]];
    },
    // 放大
    zoomIn() {
      if (!this.imageConfig.image) return;
      const scaleBy = 1.05;
      const image = this.$refs.image.getStage();
      const rotation = image.rotation();
      if (rotation === 0) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX * scaleBy).toFixed(2)),
          y: Number((oldScaleY * scaleBy).toFixed(2)),
        };
        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        console.log(x, y, image.x(), image.y(), oldScaleX, oldScaleY);
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 90) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX * scaleBy).toFixed(2)),
          y: Number((oldScaleY * scaleBy).toFixed(2)),
        };

        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 180) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX * scaleBy).toFixed(2)),
          y: Number((oldScaleY * scaleBy).toFixed(2)),
        };

        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 270) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX * scaleBy).toFixed(2)),
          y: Number((oldScaleY * scaleBy).toFixed(2)),
        };

        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      }
    },
    // 缩小
    zoomOut() {
      if (!this.imageConfig.image) return;
      const scaleBy = 1.05;
      const image = this.$refs.image.getStage();
      const rotation = image.rotation();
      if (rotation === 0) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX / scaleBy).toFixed(2)),
          y: Number((oldScaleY / scaleBy).toFixed(2)),
        };
        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 90) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX / scaleBy).toFixed(2)),
          y: Number((oldScaleY / scaleBy).toFixed(2)),
        };
        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 180) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX / scaleBy).toFixed(2)),
          y: Number((oldScaleY / scaleBy).toFixed(2)),
        };

        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      } else if (rotation === 270) {
        const oldScaleX = image.scaleX();
        const oldScaleY = image.scaleY();
        const newScale = {
          x: Number((oldScaleX / scaleBy).toFixed(2)),
          y: Number((oldScaleY / scaleBy).toFixed(2)),
        };

        const x = document.body.clientWidth / 2;
        const y = document.body.clientHeight / 2;
        const mousePointTo = {
          x: x / oldScaleX - image.x() / oldScaleX,
          y: y / oldScaleY - image.y() / oldScaleY,
        };
        image.x(-(mousePointTo.x - x / newScale.x) * newScale.x);
        image.y(-(mousePointTo.y - y / newScale.y) * newScale.y);
        image.scale(newScale);
      }
    },
    rotationImage() {
      const image = this.$refs.image.getStage();
      // image.position({ x: 0, y: 0 });
      image.scale({
        x: 1,
        y: 1,
      });
      if (this.rotationImageVal === 3) {
        this.rotationImageVal = 0;
      } else {
        this.rotationImageVal += 1;
      }
      const arr = [0, 90, 180, 270];
      if (arr[this.rotationImageVal] === 90) {
        const aspectRatio = this.imageWidth / this.imageHeight;
        this.imageConfig.height = this.stageConfig.width;
        this.imageConfig.width = this.imageConfig.height * aspectRatio;
        this.imageConfig.x = this.stageConfig.width;
        this.imageConfig.y =
          (this.stageConfig.height - this.imageConfig.width) / 2;

        image.position({ x: this.imageConfig.x, y: this.imageConfig.y });
      } else if (arr[this.rotationImageVal] === 0) {
        const aspectRatio = this.imageWidth / this.imageHeight;
        const maxWidth = this.stageConfig.width;
        const maxHeight = this.stageConfig.height;
        if (maxWidth / aspectRatio <= maxHeight) {
          this.imageConfig.width = maxWidth;
          this.imageConfig.height = maxWidth / aspectRatio;
        } else {
          this.imageConfig.width = maxHeight * aspectRatio;
          this.imageConfig.height = maxHeight;
        }
        this.imageConfig.x = 0;
        this.imageConfig.y =
          (this.stageConfig.height - this.imageConfig.height) / 2;
        image.position({ x: this.imageConfig.x, y: this.imageConfig.y });
      } else if (arr[this.rotationImageVal] === 180) {
        const aspectRatio = this.imageWidth / this.imageHeight;
        const maxWidth = this.stageConfig.width;
        const maxHeight = this.stageConfig.height;
        if (maxWidth / aspectRatio <= maxHeight) {
          this.imageConfig.width = maxWidth;
          this.imageConfig.height = maxWidth / aspectRatio;
        } else {
          this.imageConfig.width = maxHeight * aspectRatio;
          this.imageConfig.height = maxHeight;
        }
        this.imageConfig.y =
          (this.stageConfig.height - this.imageConfig.height) / 2 +
          this.imageConfig.height;
        image.position({ x: this.imageConfig.x, y: this.imageConfig.y });
      } else if (arr[this.rotationImageVal] === 270) {
        const aspectRatio = this.imageWidth / this.imageHeight;
        this.imageConfig.height = this.stageConfig.width;
        this.imageConfig.width = this.imageConfig.height * aspectRatio;
        this.imageConfig.x = 0;
        this.imageConfig.y =
          (this.stageConfig.height - this.imageConfig.width) / 2 +
          this.imageConfig.width;
        image.position({ x: this.imageConfig.x, y: this.imageConfig.y });
      }
      image.rotation(arr[this.rotationImageVal]);
    },
    beforeUpload(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageObj = new Image();
        imageObj.src = event.target.result;
        imageObj.onload = () => {
          this.imageWidth = imageObj.width;
          this.imageHeight = imageObj.height;
          const aspectRatio = imageObj.width / imageObj.height;
          const maxWidth = this.stageConfig.width;
          const maxHeight = this.stageConfig.height;
          if (maxWidth / aspectRatio <= maxHeight) {
            this.imageConfig.width = maxWidth;
            this.imageConfig.height = maxWidth / aspectRatio;
          } else {
            this.imageConfig.width = maxHeight * aspectRatio;
            this.imageConfig.height = maxHeight;
          }
          this.imageConfig.y =
            (this.stageConfig.height - this.imageConfig.height) / 2;

          this.imageConfig.image = imageObj;
         this.$nextTick(() => {
            this.$refs.layer.getNode().draw();
          });
        };
      };
      reader.readAsDataURL(file);
      return false;
    },
    tickLineInit() {
      this.tickLine = [];
      const tick = this.ticks.filter((item) => {
        return item.label.text === 0 || item.label.text === 180;
      });
      this.tickLine.push({
        points: [
          tick[0].line.points[0],
          tick[0].line.points[1],
          tick[1].line.points[0],
          tick[1].line.points[1],
        ],
        dash: [10, 5],
        stroke: "black",
        strokeWidth: 1,
      });
    },
    drawTickLine() {
      if (this.tickLine1) {
        if (Number(this.tickLine1) > 180) {
          this.tickLine1 = "180";
        } else if (Number(this.tickLine1) < 0) {
          this.tickLine1 = "0";
        }
        let tick = null;
        if (this.tickLine1 === "0" || this.tickLine1 === "180") {
          tick = this.ticks.filter((item) => {
            return item.label.text === 0 || item.label.text === 180;
          });
        } else if (this.tickLine1 === "90") {
          tick = this.ticks.filter((item) => {
            return item.label.text === 90 || item.label.text === 270;
          });
        } else {
          tick = this.ticks.filter((item) => {
            console.log("text", item.label.text);
            console.log(this.tickLine1);
            return item.label.text == this.tickLine1;
          });
        }
        console.log("tick", tick);
        if (tick.length <= 0) {
          return false;
        }
        console.log(tick);
        const startPoint = {
          x: tick[0].line.points[0],
          y: tick[0].line.points[1],
        };
        const endPoint = {
          x: tick[1].line.points[0],
          y: tick[1].line.points[1],
        };
        const slope = (endPoint.y - startPoint.y) / (endPoint.x - startPoint.x);

        const extendedStartPoint = {
          x: 0,
          y: startPoint.y - startPoint.x * slope,
        };
        const extendedEndPoint = {
          x: width,
          y: endPoint.y + (width - endPoint.x) * slope,
        };
        if (this.tickLine.length < 2) {
          this.tickLine.push({
            points: [
              extendedStartPoint.x,
              extendedStartPoint.y,
              extendedEndPoint.x,
              extendedEndPoint.y,
            ],
            dash: [10, 5],
            stroke: "red",
            strokeWidth: 1,
          });
        } else {
          this.$set(this.tickLine, 1, {
            points: [
              extendedStartPoint.x,
              extendedStartPoint.y,
              extendedEndPoint.x,
              extendedEndPoint.y,
            ],
            dash: [10, 5],
            stroke: "red",
            strokeWidth: 1,
          });
        }
      } else {
        if (this.tickLine.length >= 2) {
          this.$set(this.tickLine, 1, null);
        }
      }
      if (this.tickLine2) {
        if (Number(this.tickLine2) > 180) {
          this.tickLine2 = "180";
        } else if (Number(this.tickLine2) < 0) {
          this.tickLine2 = "0";
        }
        let tick = null;
        if (this.tickLine2 === "0" || this.tickLine2 === "180") {
          tick = this.ticks.filter((item) => {
            return item.label.text === 0 || item.label.text === 180;
          });
        } else if (this.tickLine2 === "90") {
          tick = this.ticks.filter((item) => {
            return item.label.text === 90 || item.label.text === 270;
          });
        } else {
          tick = this.ticks.filter((item) => {
            return item.label.text == this.tickLine2;
          });
        }
        if (tick.length <= 0) {
          return false;
        }
        const startPoint = {
          x: tick[0].line.points[0],
          y: tick[0].line.points[1],
        };
        const endPoint = {
          x: tick[1].line.points[0],
          y: tick[1].line.points[1],
        };
        const slope = (endPoint.y - startPoint.y) / (endPoint.x - startPoint.x);

        const extendedStartPoint = {
          x: 0,
          y: startPoint.y - startPoint.x * slope,
        };
        const extendedEndPoint = {
          x: width,
          y: endPoint.y + (width - endPoint.x) * slope,
        };
        if (this.tickLine.length < 2) {
          const extendedEndPoint = {
            x: width,
            y: endPoint.y + (width - endPoint.x) * slope,
          };
          this.tickLine.push({
            points: [
              extendedStartPoint.x,
              extendedStartPoint.y,
              extendedEndPoint.x,
              extendedEndPoint.y,
            ],
            dash: [10, 5],
            stroke: "blue",
            strokeWidth: 1,
          });
        } else {
          this.$set(this.tickLine, 2, {
            points: [
              extendedStartPoint.x,
              extendedStartPoint.y,
              extendedEndPoint.x,
              extendedEndPoint.y,
            ],
            dash: [10, 5],
            stroke: "blue",
            strokeWidth: 1,
          });
        }
      } else {
        if (this.tickLine.length >= 2) {
          this.$set(this.tickLine, 2, null);
        }
      }
    },
    createTicks() {
      this.ticks = [];
      const tickCount = 360;
      const length = 15;
      const centerX = this.stageConfig.width / 2;
      const centerY = this.stageConfig.height / 2;
      const innerRadius = this.outerRingConfig.innerRadius;
      for (let i = 0; i < tickCount; i++) {
        const labelText = this.ticksData[i].toString();
        const fontSize = 12;
        const textWidth = labelText.length * fontSize * 0.6;
        const angle = (i * 360) / tickCount;
        const x1 = centerX + innerRadius * Math.cos(this.degToRad(angle));
        const y1 = centerY + innerRadius * Math.sin(this.degToRad(angle));
        const x2 =
          centerX + (innerRadius + length) * Math.cos(this.degToRad(angle));
        const y2 =
          centerY + (innerRadius + length) * Math.sin(this.degToRad(angle));
        const tick = {
          line: {
            points: [parseInt(x1), parseInt(y1), parseInt(x2), parseInt(y2)],
            stroke: this.ticksData[i] % 10 === 0 ? "black" : "transparent",
            strokeWidth: 1,
          },
          label: {
            x: centerX + (innerRadius + 27) * Math.cos(this.degToRad(angle)),
            y: centerY + (innerRadius + 27) * Math.sin(this.degToRad(angle)),
            text: this.ticksData[i],
            fontSize: 12,
            fontFamily: "Calibri",
            fill:
              this.ticksData[i] === 0 ||
              this.ticksData[i] === 180 ||
              this.ticksData[i] === 90 ||
              this.ticksData[i] === 270
                ? "black"
                : "transparent",
            rotation: angle + 90,
            offsetX: textWidth / 2,
            offsetY: fontSize / 2,
          },
        };
        this.ticks.push(tick);
      }
    },
    degToRad(deg) {
      return (deg * Math.PI) / 180;
    },
  },
};
</script>


<style>
html {
  height: 100%;
}
.selfForm{
 position: absolute;
 padding: 12px 0 0 0;
 display: flex;
 z-index: 9;
 flex-wrap: wrap;
}
.selfForm .el-form-item.el-form-item--small{
  flex: 0 0 40%;
  white-space: nowrap;
}
.selfForm .el-form-item__label{
  font-size: 12px;
  color: #333333 !important;
}
.selfForm .el-form-item__content{
 width: 84%;
}
.publicLabelBox span{
  font-size: 12px;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 2px;
  white-space: nowrap;
  word-break: keep-all;
}
body {
  height: 100%;
  margin: 0;
}
.el-button--danger {
  background-color: #fb6767 !important;
  border-color: #fb6767 !important;
}
</style>
